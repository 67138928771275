<template>
  <b-card-code title="微信群列表">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t("message.seachLabel") }}</label>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('message.SearchPlaceholder')"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <b-modal
      ref="my-modal"
      cancel-variant="outline-secondary"
      ok-title="添加"
      cancel-title="关闭"
      centered
      no-close-on-backdrop
      title="添加监听群发"
      @ok="alert"
    >
      <b-form>
        <b-form-group>
          <label for="module">监听群:</label>
          <!-- <b-form-select
            v-model="monitorchatroomid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="monitorchatroomOptions"
            :reduce="(val) => val.value"
            :clearable="true"
            input-id="user-nickname"
            @input="indexSelect"
          /> -->
          <v-select
            v-model="monitorchatroomid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="chatroomOptions"
            @input="changeAcc"
          />
        </b-form-group>
        <b-form-group>
          <label for="deviceid">监听群成员:</label>
          <v-select
            v-model="monitorwechatid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="monitorOptions"
          />
        </b-form-group>
        <b-form-group label="发送群" label-for="dname">
          <v-select
            v-model="sendchatroomid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="chatroomOptions"
            @input="changesendAcc"
          />
          <!-- <b-form-select
            v-model="sendchatroomid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="chatroomOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="user-dname"
            @input="changesendAcc"
          /> -->
        </b-form-group>
        <b-form-group label="发送人" label-for="nickname">
          <v-select
            v-model="sendwechatid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="sendwechatidOptions"
          />
          <!-- <v-select
            v-model="sendwechatid"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="sendwechatidOptions"
            :reduce="(val) => val.label"
            :clearable="true"
            input-id="user-nickname"
          /> -->
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
      @on-selected-rows-change="selectionChanged"
    >
      <!-- Slot: Table Column -->

      <!-- Slot: Table Row -->
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'chatroomid'"
          v-b-tooltip.hover
          :title="props.row.chatroomid"
          class="text-nowrap"
        >
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ showname(props.row.nickname) }}</span>
        </span>
        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'ownername'"
          v-b-tooltip.hover
          :title="props.row.ownerid"
        >
          {{ props.row.ownername }}
        </span>
        <span
          v-else-if="props.column.field === 'atwechat'"
          v-b-tooltip.hover
          :title="props.row.wechatid"
        >
          {{ props.row.atwechat }}
        </span>
        <span v-else-if="props.column.field === 'num'">
          {{ props.row.num }}
        </span>
        <span v-else-if="props.column.field === 'qrcode'">
          <b-button
            :id="props.row.id"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >
            点击查看
          </b-button>
          <b-popover
            :target="`${props.row.id}`"
            placement="top"
            triggers="hover"
          >
            <template #title> 查看群聊二维码 </template>
            <span>
              <b-img thumbnail fluid :src="props.row.qrcode" />
            </span>
          </b-popover>
        </span>
        <span v-else-if="props.column.field === 'type'">
          <b-badge :variant="statusVariant(props.row.type)">
            {{ props.row.type == 0 ? "否" : "是" }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'rebate'">
          <b-badge :variant="statusVariant(props.row.rebate)">
            {{ props.row.rebate == 0 ? "否" : "是" }}
          </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="showModal(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>设定监听</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="gorebate(props.row)"
                :disabled="props.row.rebate == 0 ? false : true"
              >
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>设定产品</span>
              </b-dropdown-item>
              <b-dropdown-item @click="godelete(props.row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  VBTooltip,
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BModal,
  VBModal,
  BPopover,
  VBPopover,
  BImg,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import router from "@/router";
import groupset from "@/views/rebate/GroupSet";
export default {
  components: {
    VBTooltip,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    vSelect,
    BDropdownItem,
    BDropdown,
    BForm,
    BModal,
    VBModal,
    groupset,
    BPopover,
    VBPopover,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pages: ["10", "20", "40", "100"],
      pageLength: 10,
      total: 0,
      wechatid: "",
      monitorchatroomid: "",
      monitorOptions: [],
      chatroomOptions: [],
      sendchatroomid: "",
      sendchatroomidOptions: [],
      monitorwechatid: "",
      sendwechatid: "",
      sendwechatidOptions: [],
      dir: false,

      columns: [
        {
          label: "群名称",
          field: "chatroomid",
        },

        {
          label: "群主",
          field: "ownername",
        },
        {
          label: "所在微信",
          field: "atwechat",
        },
        {
          label: "人数",
          field: "num",
        },
        {
          label: "群简介",
          field: "notice",
        },
        {
          label: "群二维码",
          field: "qrcode",
        },
        {
          label: "监听群",
          field: "type",
        },
        {
          label: "产品群",
          field: "rebate",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  methods: {
    showname(title) {
      var name = "";
      console.log(title.length);
      if (title.length > 10) {
        name = title.substring(0, 10) + "...";
      } else {
        name = title;
      }
      return name;
    },
    changeAcc(chatroomid) {
      this.monitorOptions = [];

      console.log("改东西", chatroomid);
      useJwt
        .findChatRoomMemberBychatroomid({
          chatroomid: chatroomid.value,
        })
        .then((res) => {
          const forArr = res.data.data;
          console.log("forArr", forArr);
          forArr.forEach((item, i) => {
            this.monitorOptions.push({
              text: item.mwechatnick,
              value: item.mwechatid,
            });
          });
        });
    },
    selectionChanged(params) {
      this.selectid = params.selectedRows;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.selectedRows.length}`,
          icon: "UserIcon",
          variant: "success",
        },
      });
    },
    changesendAcc(chatroomid) {
      this.sendwechatidOptions = [];

      console.log("改东西1", chatroomid.value);
      useJwt
        .findChatRoomMemberBychatroomid({
          chatroomid: chatroomid.value,
        })
        .then((res) => {
          const forArr = res.data.data;
          console.log("发送人", forArr);
          forArr.forEach((item, i) => {
            this.sendwechatidOptions.push({
              text: item.mwechatnick,
              value: item.mwechatid,
            });
          });
        });
    },
    indexSelect() {},
    alert() {
      useJwt
        .Addmonitorchatroom({
          monitorchatroomid: this.monitorchatroomid.value,
          monitorwechatid: this.monitorwechatid.value,
          sendchatroomid: this.sendchatroomid.value,
          sendwechatid: this.sendwechatid.value,
          cid: this.cid,
        })
        .then((res) => {
          console.log("res", res);
          if (res.data.code == "0") {
            this.$swal({
              icon: "success",
              title: "添加成功!",
              text: "以监听成功",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },
    showModal(chatroom) {
      this.chatroomOptions = [];
      this.monitorOptions = [];
      this.sendchatroomid = "";
      this.sendwechatid = "";

      useJwt
        .findChatRoominfoByciddid({
          cid: this.cid,
          did: this.did,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          console.log("res", res);
          const forArr = res.data.data;
          forArr.forEach((item, i) => {
            this.chatroomOptions.push({
              text: item.nickname,
              value: item.chatroomid,
            });
          });
          console.log("chatroom.chatroomid", chatroom.chatroomid);
          this.chatroomOptions.forEach((item, i) => {
            console.log("item.value", item.value);
            if (item.value == chatroom.chatroomid) {
              this.monitorchatroomid = item;
              useJwt
                .findChatRoomMemberBychatroomid({
                  chatroomid: this.monitorchatroomid.value,
                })
                .then((res) => {
                  const forArr1 = res.data.data;
                  console.log("forArr1", forArr1);
                  forArr1.forEach((item, i) => {
                    this.monitorOptions.push({
                      text: item.mwechatnick,
                      value: item.mwechatid,
                    });
                  });
                });
            }
          });
        });

      this.$refs["my-modal"].show();
    },
    handleSearch(searching) {
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          nickname: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          if (this.total == 0) {
            useJwt
              .queryChatRoomAll({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
                wechatid: searching,
              })
              .then((res) => {
                this.total = res.data.data.records;
                if (this.total == 0) {
                  useJwt
                    .queryChatRoomAll({
                      cid: this.cid,
                      page: 1,
                      rows: this.pageLength,
                      ownername: searching,
                    })
                    .then((res) => {
                      this.total = res.data.data.records;
                      this.rows = res.data.data.rows;
                    });
                }
                this.rows = res.data.data.rows;
              });
          }
          this.rows = res.data.data.rows;
        });

      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    handlePageChange(active) {
      console.log("总页数" + active);
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          console.log("翻页" + JSON.stringify(res.data.data.rows));
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    godelete(chatroom) {
      this.$swal({
        title: "删除该群?",
        text: "删除后微信将会退出群聊?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt
            .quitchatroom({
              chatroomid: chatroom.chatroomid,
              wechatid: chatroom.wechatid,
            })
            .then((res) => {
              console.log("res", res);
              if (res.data.data == 1) {
                this.$swal({
                  icon: "success",
                  title: "删群成功!",
                  text: "删群成功",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
        }
      });
    },
    gorebate(chatroom) {
      console.log("chatroom.did=", JSON.stringify(chatroom));
      this.$swal({
        title: "设置该群为返利产品群?",
        text: "设置产品群?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "添加",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log("result.value", result.value);
          useJwt
            .updatebychatroomid({
              chatroomid: chatroom.chatroomid,
              wechatid: chatroom.wechatid,
              rebate: 1,
            })
            .then((res) => {
              if (res.data.code == 0) {
                console.log("res", JSON.stringify(res));
                useJwt
                  .chatroomadd({
                    cid: chatroom.cid,
                    did: chatroom.did,
                    sendwechat: chatroom.wechatid,
                    chatroomid: chatroom.chatroomid,
                  })
                  .then((res) => {
                    console.log("res1", JSON.stringify(res));
                    this.$swal({
                      icon: "success",
                      title: "添加成功!",
                      text: "以加入产品群",
                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    });
                  });
              }
            });
        }
      });
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          did: this.did,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          console.log("dasda:" + JSON.stringify(res.data.data.rows));
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    },
    gotype(chatroom) {
      if (chatroom.type == 0) {
        this.$swal({
          title: "设置该群为监听群?",
          text: "监听群里的某用户发的消息同步到其他群！",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "添加",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .updatebychatroomid({
                chatroomid: chatroom.chatroomid,
                type: 1,
              })
              .then((res) => {
                this.$swal({
                  icon: "success",
                  title: "以设为监听群!",
                  text: "",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                useJwt
                  .queryChatRoomAll({
                    cid: this.cid,
                    did: this.did,
                    wechatid: this.wechatid,
                    page: 1,
                    rows: this.pageLength,
                    desc: this.desc,
                  })
                  .then((res) => {
                    // pagelength 一页显示多少条

                    this.total = res.data.data.records;
                    this.rows = res.data.data.rows;
                  });
              });
          }
        });
      } else {
        this.$swal({
          title: "取消该群为监听群?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            useJwt
              .updatebychatroomid({
                chatroomid: chatroom.chatroomid,
                type: 0,
              })
              .then((res) => {
                this.$swal({
                  icon: "success",
                  title: "取消成功!",
                  text: "以取消该群为监听群",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                useJwt
                  .queryChatRoomAll({
                    cid: this.cid,
                    did: this.did,
                    wechatid: this.wechatid,
                    page: 1,
                    rows: this.pageLength,
                    desc: this.desc,
                  })
                  .then((res) => {
                    // pagelength 一页显示多少条
                    this.total = res.data.data.records;
                    this.rows = res.data.data.rows;
                  });
              });
          }
        });
      }
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      // if (store.state.appConfig.isRTL) {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.dir = true;
      //   return this.dir;
      // }
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.dir = false;
      // return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.wechatid = router.currentRoute.params.wechatid;
    this.cid = user.cid;
    this.did = user.did;
    if (this.wechatid != null) {
      console.log("wechatid", this.wechatid != null);
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          did: this.did,
          wechatid: this.wechatid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条

          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    } else {
      console.log("wechatid", this.wechatid == null);
      useJwt
        .queryChatRoomAll({
          cid: this.cid,
          did: this.did,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
        })
        .then((res) => {
          // pagelength 一页显示多少条

          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
